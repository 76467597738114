import React, { useContext } from "react"
import { navigate } from "gatsby"
import { FaSearch } from "react-icons/fa"
import { SearchContext } from "../context/SearchContext"
const SearchMobile = () => {
  const { setText, text, setDataSearch } = useContext(SearchContext)

  const handleChangeText = event => {
    setDataSearch([])
    const textSearch = event.target.value
    setText({ text: textSearch })
  }

  return (
    <div className="main-search-box-container">
      <div className="main-search-box-container__box">
        <div className="search-box">
          <input
            type="text"
            placeholder="メキシコの求人を検索する"
            className="input-text"
            onChange={e => handleChangeText(e)}
          />
          {/* <FaSearch
          className="faSearch"
          onClick={() => {
            navigate(`/search/?type=jobs&keyword=${text.text}`)
          }}
        /> */}
        </div>
        <button
          type="button"
          onClick={() => {
            navigate(`/search/?type=jobs&keyword=${text.text}`)
          }}
        >
          検索
        </button>
      </div>
    </div>
  )
}

export default SearchMobile
